import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import { Container } from '../components/container'
import { SectionTitle } from '../components/section-title'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'

const ManualsPage = ({ data }) => {
  const { t } = useTranslation()

  const manuals = data.manualsList.nodes
  const { title, content, slug, seoMetaTags } = data.manualInfoPage
  const updatedLinks = data.links.nodes.reduce(
    (newObj, item) => ((newObj[item.locale] = item.slug), newObj),
    {}
  )

  return (
    <Layout hrefLangLinks={updatedLinks} seoMetaTags={seoMetaTags}>
      <Container color="bg-beige">
        <SectionTitle
          title={title}
          subtitle={content.replace(/<\/?[^>]+(>|$)/g, '')}
          centered={true}
          main={true}
        />
        <ul
          role="list"
          className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
          {manuals.map(manual => (
            <li
              key={manual.title}
              className="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200"
            >
              <Link
                to={`${t('link-base')}${slug}/${manual.slug}`}
                className="flex-1 flex flex-col p-8 group"
              >
                {manual.icon && (
                  <img
                    className="w-12 h-12 flex-shrink-0 mx-auto"
                    src={manual.icon.url}
                    alt="faq image"
                  />
                )}
                <h3 className="mt-6 text-gray-900 text-sm font-medium group-hover:text-abel-green">
                  {manual.title}
                </h3>
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </Layout>
  )
}

export default ManualsPage

export const manualsQuery = graphql`
  query ($locale: String!) {
    manualsList: allDatoCmsManual(filter: { locale: { eq: $locale } }) {
      nodes {
        title
        slug
        icon {
          url
        }
      }
    }

    manualInfoPage: datoCmsInfoPage(
      code: { eq: "manual" }
      locale: { eq: $locale }
    ) {
      title
      content
      slug
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }

    links: allDatoCmsInfoPage(filter: { code: { eq: "manual" } }) {
      nodes {
        slug
        locale
      }
    }
  }
`
